body > iframe[style*="2147483647"] {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #0583ad;
  height: 150px;
  padding: 20px;
  color: green;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pagination {
  display: flex;
  justify-content: center;
  height: 40px;
  margin-top: 20px;
}

.pageItem {
  border: 1px solid black;
  width: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@font-face {
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  font-family: "Filson";
  src: url("./assets/fonts/Filson.ttf");
}
